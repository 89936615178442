import Vue from "vue";

/*TITLE*/
document.title = "Marina Vitae | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "Marina Vitae";
Vue.prototype.$subtitle = "";

/*INTRO*/
Vue.prototype.$promoter = "Marina Vitae";
Vue.prototype.$introSubtitle = "";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "porcelanosa_marinavitae_viva_salon_linkfloor-kingdom-iberia-228x180x055--20230222030255.jpg";
Vue.prototype.$image_kitchen = "porcelanosa_marinavitale_viva_cocina-cam4-linkfloor-kingdom-iberia-228x180x055-traini-beige-eucalyptus-blond--20230222030230.jpg";
Vue.prototype.$image_bath1 = "porcelanosa_marinavitae_viva_banoppal_linkfloor-kingdom-greece-228x180x055-urban-caliza-nature-316x90-deco-bremen-ash-316x90--20230222030243.jpg";
Vue.prototype.$image_bath2 = "porcelanosa_marinavitae_viva_banosec_linkfloor-kingdom-greece-228x180x055-urban-caliza-nature-316x90-cubik-urban-caliza-nature-316x90--20230222030257.jpg";
Vue.prototype.$image_room = "porcelanosa_marinavitae_viva_dormppal_linkfloor-kingdom-greece-228x180x055--20230222030253.jpg";
Vue.prototype.$image_facade = "porcelanosa_marina-vitae_fachada_v3--20230222030206.jpg";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "porcelanosa_marinavitae_viva_salon_linkfloor-kingdom-iberia-228x180x055--20230222030255.jpg",
  },
  {
    src: "porcelanosa_marinavitae_viva_salon_linkfloor-kingdom-rome-228x180x055--20230222030220.jpg",
  },
  {
    src: "porcelanosa_marinavitae_vive_salon_linkfloor-kingdom-rome-228x180x055--20230301100316.jpg",
  },
  {
    src: "porcelanosa_marinavitae_viva_salon_linkfloor-kingdom-greece-228x180x055--20230301100333.jpg",
  },
  {
    src: "porcelanosa_marinavitae_vive_salon_linkfloor-kingdom-greece-228x180x055--20230301100318.jpg",
  },
  {
    src: "porcelanosa_marinavitae_vive_salon_linkfloor-kingdom-iberia-228x180x055--20230403050402.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
  {
    src: "porcelanosa_marinavitale_viva_cocina-cam4-linkfloor-kingdom-iberia-228x180x055-traini-beige-eucalyptus-blond--20230222030230.jpg",
  },
  {
    src: "porcelanosa_marinavitale_viva_cocina-cam2-linkfloor-kingdom-iberia-228x180x055-traini-beige-eucalyptus-blond--20230222030235.jpg",
  },
  {
    src: "porcelanosa_marinavitale_viva_cocina-cam4-linkfloor-kingdom-greece-228x180x055-blanco-blanco--20230222030240.jpg",
  },
  {
    src: "porcelanosa_marinavitale_viva_cocina-cam2-linkfloor-kingdom-greece-228x180x055-blanco-blanco--20230222030246.jpg",
  },
  {
    src: "porcelanosa_marinavitae_viva_cocina-cam4_linkfloor-kingdom-rome-228x180x055-cement-grey-roble-coffee-zinc--20230222030252.jpg",
  },
  {
    src: "porcelanosa_marinavitae_viva_cocina-cam2_linkfloor-kingdom-rome-228x180x055-cement-grey-roble-coffee-zinc--20230222030255.jpg",
  },
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "porcelanosa_marinavitae_viva_banoppal_linkfloor-kingdom-greece-228x180x055-urban-caliza-nature-316x90-deco-bremen-ash-316x90--20230222030243.jpg",
  },
  {
    src: "porcelanosa_marinavitae_viva_banoppal_linkfloor-kingdom-iberia-228x180x055-vela-natural-316x90-deco-bremen-roble-natural-316x90--20230222030249.jpg",
  },
  {
    src: "porcelanosa_marinavitae_viva_banoppal_linkfloor-kingdom-rome-228x180x055-matika-bone-316x90-deco-bremen-nut-316x90--20230222030203.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "porcelanosa_marinavitae_viva_banosec_linkfloor-kingdom-greece-228x180x055-urban-caliza-nature-316x90-cubik-urban-caliza-nature-316x90--20230222030257.jpg",
  },
  {
    src: "porcelanosa_marinavitae_viva_banosec_linkfloor-kingdom-iberia-228x180x055-vela-natural-316x90-limit-vela-natural-316x90--20230222030202.jpg",
  },
  {
    src: "porcelanosa_marinavitae_viva_banosec_linkfloor-kingdom-rome-228x180x055-matika-bone-316x90-block-matika-bone-316x90--20230222030207.jpg",
  },
];

Vue.prototype.$gallery_images_room = [
  {
    src: "porcelanosa_marinavitae_viva_dormppal_linkfloor-kingdom-greece-228x180x055--20230222030253.jpg",
  },
  {
    src: "porcelanosa_marinavitae_viva_dormppal_linkfloor-kingdom-iberia-228x180x055--20230222030258.jpg",
  },
  {
    src: "porcelanosa_marinavitae_viva_dormppal_linkfloor-kingdom-rome-228x180x055--20230222030203.jpg",
  },
  {
    src: "porcelanosa_marinavitae_vivc_dormppal_linkfloor-kingdom-rome-228x180x055--20230301100331.jpg",
  },
];

Vue.prototype.$gallery_images_facade = [
  {
    src: "porcelanosa_marina-vitae_fachada_v3--20230222030206.jpg",
  },
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "3";
Vue.prototype.$gallery_grid_bath2 = "3";
Vue.prototype.$gallery_grid_room = "6";
Vue.prototype.$gallery_grid_facade = "6";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "https://clientes.ppgstudios.com/porcelanosa/Marina_Vitale/";

/*3D FILM*/
Vue.prototype.$3dFilm = "https://player.vimeo.com/video/808321227?h=377838e192";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "https://spaces.porcelanosa.com/tag/marinavitae/?e=iframe";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=c%20juan%20verdeguer%2050%2046024%20valencia%20",
    text: "C/ Juan Verdeguer 50, 46024 (Valencia)",
  },
];

Vue.prototype.$showroom = [
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:963942282",
    text: "963942282",
  },
  {
    icon: "mdi-email",
    link: "mailto:info@olivaresconsultores.com",
    text: "info@olivaresconsultores.com",
  },
];
